import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {routes} from "@/route/routes";
import Router from 'vue-router'
import VueCodeMirror from 'vue-codemirror';




Vue.use(Router)
Vue.use(VueCodeMirror)
Vue.use(ElementUI)



export default Vue

/*router.beforeEach((to,from,next)=>{

})*/

export const router =  new Router({
    mode:'history',
    routes
})

