const errRoutes = [
    {
        path:'error:*',
        component:()=>import('@/views/error/not-found.vue')
    },
    {
        path:'*',
        component:()=>import('@/views/error/not-found.vue')
    }
]

export const routes = [
    {path:'/',component:()=>import('@/views/Home/Home.vue')},
    {path:'/home',component:()=>import('@/views/Home/Home.vue')},
    ...errRoutes
]


