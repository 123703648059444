import Vue,{router} from '@/route'
import App from './App.vue'


Vue.config.productionTip = false





new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
