<template>
  <div id="app">
    <main-page></main-page>
  </div>
</template>

<script>
export default {
  components:{
    'main-page':()=>import('@/main.vue')
  },
  name: 'App',
  data:function () {
     return {

     }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
